body#exercises.prev{

	input{
		cursor: text;
		pointer-events: none;
	}

	.btn-send, .btn-save{
		display: none;
	}

	.site-nav{
		//display: none;
	}
	#timer{
		display: none;
	}

}