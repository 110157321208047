#login-form-box{
	position: relative;
	z-index: 9999;

	a{
		color: inherit;
	}

	.login-form-box-inner {
		position: fixed;
		z-index: 10;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);

		width: 620px;
		//min-height: 600px;
		background: green;
		background-image: url("../img/login-form-bg.jpg");
		background-size: cover;
		border-radius: 20px;
		box-shadow: 0 2px 8px 0 rgba(0,0,0,0.44);
		padding: 90px 125px;
		padding-bottom: 56px;
	}

	.login-form-box-body{
		background: rgba(#fff, .4);
		padding: 45px;
		border-radius: 20px;
		font-size: 15px;
		color: #54657e;

		.input-group-text{
			background: #fff;
			width: 45px;
		}

		.avatar-ico{
			width: 83px;
			position: absolute;
			top: 55px;
			left: 50%;
			transform: translateX(-50%);
			opacity: .8;
		}

		input:not([type=checkbox]){
			min-height: 45px;
			background: #eceff4;
		}

		button{
			padding-left: 40px;
			padding-right: 40px;
		}

		input, label, p{
			margin: 0;
		}

		input[type=checkbox]{
			position: absolute;
			left: -3000px;
			display: none;
		}
		input[type=checkbox] + label{
			width: 25px;
			height: 25px;
			background: #fff;
			border-radius: 4px;
			border: 1px solid #b3b3b3;
			display: block;
			position: relative;
			cursor: pointer;
		}
		input[type=checkbox]:checked + label:after{
			content: '';
			width: 15px;
			height: 11px;
			display: block;
			background: url("../img/checkbox-check.png") no-repeat;
			position: absolute;
			top: 6px;
			left: 4px;
		}
		.checkbox-text{
			margin-top: 2px;
			margin-left: 7px;
			font-size: 14px;
		}
		.forgot-password{
			font-size: 12px;
			font-style: italic;
		}

		svg{
			width: 20px;

			path{
				fill: #8493a8;
			}
		}
	}

	.login-form-box-footer{
		border-top: 2px solid #fff;
		margin-top: 50px;
		font-size: 12px;
		color: #fff;
		text-align: center;

		p{
			margin: 0;
			margin-top: 5px;
		}
	}

	.login-form-curtine{
		position: fixed;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		background: rgba(#fff, .8);
	}

}