@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&subset=latin-ext');

$font-primary: 'Roboto', sans-serif;

$font-regular: 400;
$font-medium: 500;
$font-bold: 700;

$color-text: #54657e;
$color-text-light: #8493a8;
$color-green: #39b54a;
$color-blue: #0082d5;
//$color-orange: #FC601D;


//*{
//    box-shadow: 0px 0px 0px 1px rgba(red, .2);
//}
//.container{
//    box-shadow: 0px 0px 0px 1px rgba(green, .4);
//}
//.row{
//    box-shadow: 0px 0px 0px 1px rgba(blue, .2);
//}