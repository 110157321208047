body#exercises{
	font-family: $font-primary;
	margin: 0;
	padding: 0;
	//background: url('../img/level1-bg.jpg');
	background-repeat: no-repeat;
	//background-size: cover;
	background-position: 15% center;
	font-size: 15px;
	min-height: 100vh;

	padding-bottom: 90px;
	position: relative;

	#app[v-cloak]{
		opacity: 0;
	}

	//*{
		//box-sizing: border-box;
	//}

	.valid{
		outline: 2px solid green !important;
	}
	.invalid{
		outline: 2px solid red !important;
	}

	ul.reset{
		padding-left: 0 !important;
		padding-right: 0 !important;
		margin-left: 0 !important;
		margin-right: 0 !important;

		li{
			list-style: none;
		}
	}

	.exercise-section {
		height: 510px;
		overflow-x: hidden;
		padding-bottom: 40px;
		padding-right: 15px;

		@media (max-width: 1450px){
			height: 420px;
		}
	}

	#content-box{
		display: block;
		width: 912px;
		height: 709px;
		margin-top: 80px;
		//margin-left: 265px;
		margin-left: 15%;
		background: url('../img/level1-content-box.png') no-repeat;
		background-size: 100% 100%;
		padding: 59px 72px 0 135px;
		position: relative;

		@media (max-width: 1450px){
			margin-top: 20px;
			height: 600px;
		}

		@media (max-width: 1200px){
			margin-left: 14%;
		}
	}

	.question{
		font-size: 18px;
		margin-top: 10px;
		margin-bottom: 10px;
	}

	.answears{
		width: 100%;
		margin-top: 30px;
		position: relative;

		p{
			line-height: 1.5;
		}
	}

	.input-text{
		border: none;
		background: transparent;
		border-bottom: 1px solid #000;
		outline: none;
		font-size: inherit;
		text-align: center;
		transition: .6s all;

		&:focus{
			border-color: $color-blue;
		}
	}
	.input-clean{
		border: none;
		background: transparent;
		outline: none;
		font-size: inherit;
		text-align: center;
	}

	.lesson-info-box{
		position: absolute;
		//bottom: 70px;
		//left: 100px;
		bottom: -10px;
		left: -160px;

		display: flex;
		justify-content: flex-start;
		align-items: center;
		//overflow: hidden;
		z-index: 2;

		.lesson-number{
			font-size: 75px;
			font-weight: $font-bold;
			color: #fff;
			text-shadow: 1px 2px #000;
			width: 150px;
			text-align: right;
			line-height: 0;
			position: relative;
			top: 2px;
		}
		.box{
			width: 350px;
			background: #143171;
			padding: 7px 30px;
			color: #fff;
			position: relative;
			height: 58px;
			box-shadow: 0px 2px 0px 0px #000;

			&:after, &:before{
				content: '';
				position: absolute;
				display: block;
				background: #143171;
				width: 40px;
				height: 40px;
				top: 50%;
				right: -20px;
				transform: translateY(-50%) rotate(45deg);
				z-index: -1;
			}

			&:before{
				background: #000;
				top: calc(50% + 2px);
			}
		}
		.title{
			font-weight: $font-bold;
			font-size: 110%;
			position: relative;
			z-index: 2;
		}
		.description{
			font-size: 80%;
			position: relative;
			z-index: 2;
		}
	}

	#request-number{
		position: absolute;
		bottom: 0;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: flex-end;

		svg{
			width: 600px;
		}
	}

	.digital-clock{
		background: none;
		border: 1px solid rgba(0,0,0, .5);
		text-align: center;
		font-size: 22px;
		border-radius: 15px;
		width: 116px;
		padding: 5px 0;
		display: inline-block;
		outline: none;
	}

	#measure-menu{
		position: fixed;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		z-index: 1000;
		pointer-events: none;

		ul{
			list-style: none;
			padding: 0;
		}
		li{
			pointer-events: all;
			margin: 15px 0;
			border-radius: 0 15px 15px 0;
			min-height: 80px;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 13px;
			color: #fff;
			cursor: pointer;

			background: #2cd664; /* Old browsers */
			background: -moz-linear-gradient(top, #2cd664 0%, #0082d5 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(top, #2cd664 0%,#0082d5 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to bottom, #2cd664 0%,#0082d5 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2cd664', endColorstr='#0082d5',GradientType=0 ); /* IE6-9 */

			transform: translateX(-284px);
			transition: .8s transform;

			&:hover{
				transform: translateX(0);
			}

			span{
				width: 280px;
				padding-left: 10px;
			}
			div{
				margin-left: 20px;
				width: 60px;
			}
		}
	}

	#ruler2{
		width: 346px;
		height: 185px;
		background: red;
		background: url('../img/protractor.png');
		position: fixed;
		top: 0;
		left: 0;
		//background-repeat: no-repeat;
		//background-size: contain;
		display: none;
		z-index: 1001;
	}

	#ruler3{
		width: 466px;
		height: 40px;
		background: red;
		background: url('../img/ruler.png');
		position: fixed;
		top: 0;
		left: 0;
		//background-repeat: no-repeat;
		//background-size: contain;
		display: none;
		z-index: 1001;
	}

	.fract{
		font-size: 18px;
		display: inline-flex;
		align-items: center;
		position: relative;
		top: -2px;

		.index{
			display: flex;
			flex-direction: column;
			position: relative;
		}
		.index span{
			text-align: center;
			font-size: 60%;
		}
		.index span:first-child:after {
			content: '';
			display: block;
			position: absolute;
			width: 100%;
			height: 1px;
			background: #000;
		}
	}

}