#timer{
	list-style: none;
	padding: 0;
	margin: 0;
	display: flex;
	justify-content: center;
	align-items: center;

	position: fixed;
	top: 5px;
	right: 20px;

	@media (max-width: 1200px) {
		left: 960px;
	}

	li{
		width: 93px;
		height: 94px;
		background-image: url('../img/timer-bg.png');
		display: flex;
		justify-content: center;
		align-items: center;
		flex-shrink: 0;

		div{
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			line-height: 1;
			color: $color-green;

			.time{
				font-size: 33px;
			}
			.title{
				font-size: 9px;
				position: relative;
				top: -3px;
				text-transform: uppercase;
			}
		}
	}
}