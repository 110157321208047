body, html{
	min-width: 1024px;
	min-height: 100vh;
}
.container{
	min-width: 960px;
}

.swal2-popup .swal2-title{
	font-weight: 400 !important;
	font-size: 1.475em !important;
}

body.student-dashboard {
	font-family: $font-primary;
	background: url("../img/dashboard-island-bg-2-2.jpg") no-repeat center;

	.new-badge{
		display: none;
	}
	.slick-arrow{
		position: absolute;
		background: none;
		border: none;
		width: 100px;
		height: 127px;
		font-size: 0;
		line-height: 0;
		cursor: pointer;
		z-index: 999;
		outline: none;
		top: 46%;
		transition: .3s opacity;

		&.slick-disabled{
			opacity: 0;
		}

		&:hover{
			animation-name: jump-arrow;
			animation-iteration-count: infinite;
			animation-duration: 1.5s;
		}
	}
	.slick-prev{
		background-image: url("../img/arrow-left.png");
		left: 60px
	}
	.slick-next{
		background-image: url("../img/arrow-right.png");
		right: 60px;
	}

	@keyframes jump-arrow {
		0% {transform: perspective(1px) translateX(0px); }
		50% {transform: perspective(1px) translateX(5px);}
		100% {transform: perspective(1px) translateX(0px);}
	}

	.island-slider {
		position: relative;
		min-width: 1024px;
	}
	.island-container{
		position: relative;
		height: 862px;
		outline: none;
	}
	.island {
		display: block;
		position: absolute;
		outline: none;

		@media (max-width: 1400px) {
			transform: scale(.9);
		}
		@media (max-width: 1200px) {
			transform: scale(.8);
		}

		&:not(.island-0):hover{
			animation-name: jump;
			animation-iteration-count: infinite;
			animation-duration: 2s;
		}
	}
	.island-0 {
		background-image: url("../img/island-0.png");
		width: 382px;
		height: 313px;
		top: 195px;
		left: -4%;

		@media (max-width: 1024px) {
			top: 238px;
			left: -9%;
		}
	}
	.island-1 {
		background-image: url("../img/island-1.png");
		width: 531px;
		height: 278px;
		top: 520px;
		left: 10%;

		@media (max-width: 1024px) {
			top: 520px;
			left: 0;
		}

		.book{
			top: 77px;
			left: 150px;
		}
	}
	.island-2 {
		background-image: url("../img/island-2.png");
		width: 514px;
		height: 402px;
		top: 37px;
		left: 23%;
		z-index: 2;

		@media (max-width: 1024px) {
			top: 37px;
			left: 14%;
		}

		.book{
			top: 199px;
			left: 175px;
		}
	}
	.island-3 {
		background-image: url("../img/island-3.png");
		width: 543px;
		height: 450px;
		top: 412px;
		left: 50%;

		@media (max-width: 1024px) {
			top: 412px;
			left: 48%;
		}

		.book{
			top: 214px;
			left: 140px;
		}
	}
	.island-4{
		background-image: url("../img/island-4.png");
		width: 806px;
		height: 443px;
		top: 0;
		left: 47%;

		@media (max-width: 1024px) {
			top: 0;
			left: 38%;
		}

		.book{
			top: 180px;
			left: 321px;
		}
	}
	.book{
		position: absolute;
	}

	@keyframes jump {
		0% {transform: perspective(1px) translateY(0px);}
		50% {transform: perspective(1px) translateY(3px);}
		100% {transform: perspective(1px) translateY(0px);}
	}
	@media (max-width: 1400px) {
		@keyframes jump {
			0% {transform: perspective(1px) translateY(0px) scale(.9);}
			50% {transform: perspective(1px) translateY(3px) scale(.9);}
			100% {transform: perspective(1px) translateY(0px) scale(.9);}
		}
	}
	@media (max-width: 1200px) {
		@keyframes jump {
			0% {transform: perspective(1px) translateY(0px) scale(.8);}
			50% {transform: perspective(1px) translateY(3px) scale(.8);}
			100% {transform: perspective(1px) translateY(0px) scale(.8);}
		}
	}

}