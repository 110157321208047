.hexagon-group{
	text-align: center;
	position: relative;
	height: 775px;
	width: 1117px;
	margin: 0 auto;
	margin-top: 50px;

	@media(max-width: 1400px) {
		height: 603px;
		width: 866px;
		margin-top: 15px;
	}

	@media(max-height: 740px) {
		transform: scale(.85);
		transform-origin: top;
	}

	.hexagon-box{
		width: 450px;
		display: inline-block;
		position: absolute;
		top: 0;
		left: 0;

		&.box-1{
			top: 0;
			left: 0;
		}
		&.box-2{
			top: 193px;
			left: 333px;
		}
		&.box-3{
			top: 0;
			left: 666px;
		}
		&.box-4{
			top: 385px;
			left: 0;
		}
		&.box-5{
			top: 385px;
			left: 666px;
		}

		@media(max-width: 1400px){
			width: 350px;

			&.box-1{
				top: 0;
				left: 0;
			}
			&.box-2{
				top: 151px;
				left: 258px;
			}
			&.box-3{
				top: 0;
				left: 516px;
			}
			&.box-4{
				top: 299px;
				left: 0;
			}
			&.box-5{
				top: 299px;
				left: 516px;
			}
		}

		svg{
			display: block;
		}

		ul{
			padding: 0;
			margin: 0;
			list-style: none;
			width: 100%;
			position: absolute;
			bottom: 30px;
			display: flex;
			justify-content: center;

			li{
				display: block;
				width: 82px;
				height: 59px;
				background-image: url("../img/hex-content-bg.png");
				margin: 0 17px;
				position: relative;

				a{
					display: block;
					width: 82px;
					height: 59px;
					text-decoration: none;
				}

				.lesson-number{
					color: #000;
					text-decoration: none;
					position: absolute;
					top: 1px;
					left: 43%;
					font-size: 24px;
					font-weight: $font-bold;
				}

				.ico-star1, .ico-star2, .ico-star3, .ico-lock, .ico-check, .ico-close{
					position: absolute;
				}
				.ico-star1{
					top: 39px;
					left: 6px;
				}
				.ico-star2{
					top: 35px;
					left: 26px;
				}
				.ico-star3{
					top: 33px;
					left: 50px;
				}
				.ico-lock{
					top: -12px;
					left: -13px;
				}
				.ico-check{
					top: -10px;
					left: -11px;
				}
				.ico-close{
					top: -5px;
					left: -13px;
				}
			}
		}
	}
}



.hexagon-group-2{
	text-align: center;
	position: relative;
	height: 775px;
	width: 898px;
	margin: 0 auto;
	margin-top: 50px;

	@media(max-width: 1400px) {
		height: 603px;
		width: 693px;
		margin-top: 15px;
	}

	.hexagon-box{
		width: 351px;
		display: inline-block;
		position: absolute;
		top: 0;
		left: 0;

		&.box-1{
			top: 158px;
			left: 0;
		}
		&.box-2{
			top: 0;
			left: 274px;
		}
		&.box-3{
			top: 158px;
			left: 547px;
		}
		&.box-4{
			top: 316px;
			left: 274px;
		}
		&.box-5{
			top: 471px;
			left: 0;
		}
		&.box-6{
			top: 471px;
			left: 547px;
		}

		@media(max-width: 1400px){
			width: 270px;

			&.box-1{
				top: 124px;
				left: 0;
			}
			&.box-2{
				top: 0px;
				left: 212px;
			}
			&.box-3{
				top: 120px;
				left: 423px;
			}
			&.box-4{
				top: 244px;
				left: 212px;
			}
			&.box-5{
				top: 368px;
				left: 0;
			}
			&.box-6{
				top: 363px;
				left: 422px;
			}
		}

		svg{
			display: block;
		}

		ul{
			padding: 0;
			margin: 0;
			list-style: none;
			width: 100%;
			position: absolute;
			bottom: 24px;
			display: flex;
			justify-content: center;

			@media(max-width: 1400px) {
				bottom: 13px;
			}

			li{
				display: block;
				width: 56px;
				height: 40px;
				background-image: url("../img/hex-content-bg-small.png");
				margin: 0 17px;
				position: relative;

				@media(max-width: 1400px){
					margin: 0 11px;
				}

				a{
					display: block;
					width: 56px;
					height: 40px;
					text-decoration: none;
				}

				.lesson-number{
					color: #000;
					text-decoration: none;
					position: absolute;
					top: 1px;
					left: 46%;
					font-size: 16px;
					font-weight: $font-bold;
				}

				.ico-star1, .ico-star2, .ico-star3, .ico-lock, .ico-check, .ico-close{
					position: absolute;
				}
				.ico-star1{
					top: 27px;
					left: 4px;
				}
				.ico-star2{
					top: 24px;
					left: 18px;
				}
				.ico-star3{
					top: 23px;
					left: 34px;
				}
				.ico-lock{
					top: -8px;
					left: -13px;
				}
				.ico-check{
					top: -8px;
					left: -11px;
				}
				.ico-close{
					top: -3px;
					left: -11px;
				}
			}
		}
	}
}