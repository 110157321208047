@media print {

	.site-nav, #timer, #measure-menu, .lesson-info-box, #exercise-nav-box {
		display: none !important;
	}
	.exercise-section {
		display: block !important;
		padding: 100px !important;
		page-break-after: always;
	}

	#content-box {
		width: 100% !important;
		height: initial !important;
		margin: 0 !important;
		padding: 0 !important;
	}

	body#exercises .exercise-section {
		height: initial;
		overflow: initial;
		padding: 0;
	}

	.question {
		font-size: 30px !important;
	}

}