.site-nav{
	font-size: 15px;
	font-weight: $font-bold;

	.site-nav-bg {
		background: rgba(#F9FDFE, .87);
		border-radius: 5px;
	}

	.nav-row{
		height: 100px;
	}

	.ico{
		width: 25px;
		height: 25px;

		path{
			fill: $color-text-light;
			transition: .6s all;
		}
	}

	.nav-item{
		height: 100%;
		border-right: 1px solid lighten($color-text-light, 20%);
		text-decoration: none;
		color: $color-text;
		transition: .6s all;

		&:last-child{
			border: none;
		}

		&.hover:hover{
			background: lighten($color-text-light, 35%);
			color: $color-green;

			.ico path{
				fill: $color-green;
			}
		}
		.user-avatar{
			width: 50px;
			border: double 2px transparent;
			border-radius: 50%;
			background-image: linear-gradient(white, white), radial-gradient(circle at left, $color-green,$color-blue);
			background-origin: border-box;
			background-clip: content-box, border-box;
		}
		.name{
			font-size: 15px;
		}
		.level{
			font-size: 14px;
			color: $color-text-light;
		}
		.user-ico{
			width: 20px;

			path{
				fill: $color-text-light;
				transition: .6s all;
			}

			&:hover path{
				fill: $color-green;
			}
		}
	}
}